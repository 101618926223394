





















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BaseView: () => import('./View.vue'),
    AppBar: () => import('./AppBar.vue')
  },

  computed: {
    ...mapGetters(['getThemeMode']),

    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  }
});
